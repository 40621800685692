<template>
  <div>
    <el-row type="flex" justify="space-between" class="row_bottom">
      <el-col :span="22">
        <span class="span-total">共 {{total}} 条数据</span>
      </el-col>
      <el-col :span="5" class="buttons">
        <el-button type="success" size="small" @click="handleAdd_onClick()">新增</el-button>
      </el-col>
    </el-row>

    <el-dialog title="新增" class="comDialogTop" :visible.sync="addFormVisible" width="25%" :modal="false">
      <el-form :model="form" class="FilterCriteria" ref="form" :rules="rules" label-width="120px">
        <el-form-item label="模板ID" v-show="false">
          <el-input v-model="form.MenuTemplateId" size="small"></el-input>
        </el-form-item>
        <el-form-item label="模板名称" prop="MenuTemplateName">
          <el-input v-model="form.MenuTemplateName" size="small"  style="width:80%"></el-input>
        </el-form-item>
        <el-form-item label="菜单类型:" prop="MenuTemplateType">
          <el-select size="small" style="width:80%"
            v-model="form.MenuTemplateType"
            placeholder="请选择"
            filterable
            collapse-tags
          >
            <el-option
              v-for="item in MenuTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer buttons">
        <el-button type="success" size="small" @click="addFormVisible = false">取消</el-button>
        <el-button type="success" size="small" @click="handleAdd">保存</el-button>
      </div>
    </el-dialog>

    <el-table
      empty-text="-"
      v-loading="loading"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(31,41,48,0)"
      :fit="true"
      :stripe="true"
      :data="tableData"
      class="table_height"
      :header-cell-style="{ 'background':BackgroundColors}"
    >
      <el-table-column v-if="false" type="index" label="序号"></el-table-column>
      <el-table-column sortable prop="MenuTemplateId" label="模板ID"></el-table-column>
      <el-table-column prop="MenuTemplateType" label="菜单类型"></el-table-column>
      <el-table-column label="模板名称" prop="MenuTemplateName" sortable></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button class="el-icon-r10" size="mini" type="success" @click="handleDetail_onClick(scope.row)">详情</el-button>
          <i class="el-icon-edit el-icon-r10" @click="handleUpdate_onClick(scope.row)"></i>
          <i class="el-icon-delete" @click="handleDelete(scope.row)"></i>
        </template>
      </el-table-column>
      <slot></slot>
      <template slot="empty" v-if="!loading">
        <img :src="empty01" />
        <div>暂无数据</div>
      </template>
    </el-table>

    <el-dialog title="修改" class="comDialogTop" :visible.sync="updateFormVisible" width="25%" :modal="false">
      <el-form :model="form" class="FilterCriteria" ref="form" :rules="rules" label-width="120px">
        <el-form-item label="模板ID" prop="MenuTemplateId">
          <span class="value-style">{{form.MenuTemplateId}}</span>
        </el-form-item>
        <el-form-item label="模板名称" prop="MenuTemplateName">
          <el-input v-model="form.MenuTemplateName" size="small"  style="width:80%"></el-input>
        </el-form-item>
        <el-form-item label="菜单类型:" prop="MenuTemplateType">
          <el-select size="small"
            v-model="form.MenuTemplateType"
            placeholder="请选择"
            filterable
            collapse-tags
            style="width:80%"
          >
            <el-option
              v-for="item in MenuTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer buttons">
        <el-button type="success" size="small" @click="updateFormVisible = false">取消</el-button>
        <el-button type="success" size="small" @click="handleUpdate()">提交修改</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="detailTitle" class="comDialogTop" :visible.sync="detailFormVisible" width="80vw" :modal="false">
      <MenuDetail
        ref="MenuDetail"
        :MenuTemplateId="TemplateId"
        :detailFormVisible="detailFormVisible"
      ></MenuDetail>
    </el-dialog>
  </div>
</template>

<script>
import { formatDate } from '@/common/js/date.js'
// import { fetch } from '@/axios/fetch'
import { isEmpty } from '@/common/js/base.js'
import empty01 from '@/assets/icons/empty02.png'
import MenuDetail from './MenuDetail'
import {
  GetMenuTemplateList,
  AddMenuTemplate,
  ModifyMenuTemplate,
  DeleteMenuTemplate,
} from "@/api/menuRole"; //页面接口

export default {
  components: {
    MenuDetail,
  },
  data() {
    return {
      searchForm: {
        date: formatDate(new Date(), 'yyyy-MM-dd'),
      },
      empty01: empty01,
      //excel upload
      uploadLoading: false,
      progressPercent: 0,
      showProgress: false,
      showRemoveFile: false,
      file: null,
      tableData: [],
      tableTitle: [],
      tableLoading: false,

      MenuTypeList: [
        {
          value: 'PC',
          label: 'PC',
        },
        {
          value: 'Mobile',
          label: 'Mobile',
        },
      ],
      form: {
        MenuTemplateId:null,
        MenuTemplateName:null,
        MenuTemplateType:null,
      },
      TemplateId: '',

      detailTitle: '',

      total: 0,

      loading: true,

      addFormVisible: false,
      updateFormVisible: false,
      detailFormVisible: false,

      statusFormScope: null,

      rules: {
        TemplateName: [
          { required: true, message: '请输入模板名称', trigger: 'blur' },
        ],
        MenuType: [
          { required: true, message: '请选择菜单类型', trigger: 'blur' },
        ],
      },

      BackgroundColors:sessionStorage.getItem('BgColorType')=='white'?'#f9f9f9':'#2a343b',
    }
  },
  computed: {},
  watch: {
    '$store.state.testTxt.tips'(newVal,oldVal){
			if(newVal=='white'){
				this.BackgroundColors = '#f9f9f9';
			}else{
				this.BackgroundColors = '#2a343b';
			}
		},
  },
  mounted() {

  },
  methods: {
    //获得列表
    getMenuRecords() {
      this.loading = true
      this.total = 0
      GetMenuTemplateList({
        
      }).then((res) => {
        let obj = res.Content;
        if(res.Code==-1) {
          this.$notify.info({
            title:'消息',
            message: '菜单模板列表查询失败'
          });
          return;
        }
        this.total = obj.length
        this.tableData = obj
        this.loading = false
      })
      .catch((err) => {

      });
    },







    //excel upload
    initUpload() {
      this.file = null
      this.showProgress = false
      this.loadingProgress = 0
      this.tableData = []
      this.tableTitle = []
    },
    handleUploadFile() {
      this.initUpload()
    },
    handleBeforeUpload(file) {
      const fileExt = file.name.split('.').pop().toLocaleLowerCase()
      if (fileExt === 'xlsx' || fileExt === 'xls') {
        this.readFile(file)
        this.file = file
      } else {
        this.$Notice.warning({
          title: '文件类型错误',
          desc:
            '文件：' +
            file.name +
            '不是EXCEL文件，请选择后缀为.xlsx或者.xls的EXCEL文件。',
        })
      }
      return false
    },
    // 读取文件
    readFile(file) {
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)
      reader.onloadstart = (e) => {
        this.uploadLoading = true
        this.tableLoading = true
        this.showProgress = true
      }
      reader.onprogress = (e) => {
        this.progressPercent = Math.round((e.loaded / e.total) * 100)
      }
      reader.onerror = (e) => {
        this.$Message.error('文件读取出错')
      }
      reader.onload = (e) => {
        this.$Message.info('文件读取成功')
        const data = e.target.result
        const { header, results } = excel.read(data, 'array')
        const tableTitle = header.map((item) => {
          return { title: item, key: item }
        })
        this.tableData = results
        this.tableTitle = tableTitle
        this.uploadLoading = false
        this.tableLoading = false
        this.showRemoveFile = true

        // let excelData = [];
        // for(let i=0; i<results.length; i++){
        //   excelData.push({type:results[i].type, name:results[i].name, id:results[i].id, key:results[i].key, parent:results[i].parent});
        // }
        this.upload(JSON.stringify(results))
      }
    },
    upload(excelData) {
      fetch({
        // url: this.global.serverPath+'api/upload',
        method: 'post',
        data: excelData,
      })
        .then((res) => {
          if (res.data.Code == -1 && res.data.Message) {
            this.$notify.info({
              title: '消息',
              message: res.data.Message,
            })
            return
          }

          this.$notify.info({
            title: '消息',
            message: '上传成功',
          })
          this.getRecords()
        })
        .catch((err) => {
          this.$notify.info({
            title: '消息',
            message: err.data.Message,
          })
        })
    },
    //end excel upload

    handleDetail_onClick(row) {
      this.detailFormVisible = true
      this.detailTitle = '模板' + row.MenuTemplateName + '详情'
      this.TemplateId = row.MenuTemplateId
      this.$nextTick(() => {
        this.$refs.MenuDetail.getRecords();
        // this.$refs.MenuDetail.getDefalutMenuTemplate();

      })
    },
    handleAdd_onClick() {
      this.form = {
        MenuTemplateId:null,
        MenuTemplateName:null,
        MenuTemplateType:null,
      }
      this.addFormVisible = true
    },
    handleAdd() {
      this.$refs['form'].validate((valid) => {
        if (!valid) {
          return
        } else {
          AddMenuTemplate({
            ...this.form
          }).then((res) => {
            if(res.Code==-1) {
              this.$notify.info({
                title:'消息',
                message: '菜单模板新增失败'
              });
              return;
            }
            this.$notify.info({
              title:'消息',
              message: '菜单模板新增成功'
            });
            this.getMenuRecords()
            this.addFormVisible = false
          })
          .catch((err) => {

          });
        }
      })
    },
    handleUpdate_onClick(row) {
      this.form = Object.assign({},row)
      this.updateFormVisible = true
    },
    handleUpdate() {
      this.$refs['form'].validate((valid) => {
        if (!valid) {
          return
        } else {  
          ModifyMenuTemplate({
            ...this.form
          }).then((res) => {
            if(res.Code==-1) {
              this.$notify.info({
                title:'消息',
                message: '菜单模板修改失败'
              });
              return;
            }
            this.$notify.info({
              title:'消息',
              message: '菜单模板修改成功'
            });
            this.getMenuRecords()
            this.updateFormVisible = false
          })
          .catch((err) => {

          });
        }
      })
    },
    handleDelete(row) {
      this.$confirm('您确定删除吗？')
        .then((_) => {
        DeleteMenuTemplate({
          MenuTemplateId: row.MenuTemplateId
        }).then((res) => {
          if(res.Code==-1) {
            this.$notify.info({
              title:'消息',
              message: '菜单模板删除失败'
            });
            return;
          }
          this.$notify.info({
            title:'消息',
            message: '菜单模板删除成功'
          });
          this.getMenuRecords()
        })
        .catch((err) => {

        });
      })
        .catch((_) => {})
    },










    format(row, column, cellValue, index) {
      if (!isEmpty(cellValue)) {
        return formatDate(new Date(cellValue), 'yyyy-MM-dd hh:mm:ss')
      }
    },
    //获得列表
    getRecords() {
      this.loading = true
      this.total = 0
      this.tableData = []
      fetch({
        url: this.global.serverPath + 'sys/api/Menu/GetMenuTemplateList',
        // url: '../static/data/sysMagData/Model.json',
        method: 'get',
        params: {},
      })
        .then((res) => {
          let list = res.data.Content
          if (res.data.Code == -1 && res.data.Message) {
            this.$notify.info({
              title: '消息',
              message: res.data.Message,
            })
          }
          if (isEmpty(res.data.Content)) {
            this.loading = false
            return
          }
          this.total = list.length
          for (let i = 0; i < list.length; i++) {
            this.tableData.push({
              TemplateId: list[i].MenuTemplateID,
              TemplateName: list[i].MenuTemplateName,
              MenuType: list[i].MenuType,
              AgencyId: list[i].AgencyId,
            })
          }

          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          this.$notify.info({
            title: '消息',
            message: err.data.Message,
          })
        })
    },
    //增加
    addRecord() {
      fetch({
        url: this.global.serverPath + 'sys/api/MenuTemplates',
        // url: '../static/data/sysMagData/UnResolvedAlarm-stuts.json',
        method: 'post',
        params: {
          menuTemplateName: this.form.TemplateName,
          MenuType: this.form.MenuType,
        },
      })
        .then((res) => {
          if (res.data.Code == -1 && res.data.Message) {
            this.$notify.info({
              title: '消息',
              message: res.data.Message,
            })
            this.getRecords()
            return
          }

          this.$notify.info({
            title: '消息',
            message: '保存成功',
          })
          this.getRecords()
        })
        .catch((err) => {
          this.$notify.info({
            title: '消息',
            message: err.data.Message,
          })
          this.getRecords()
        })
    },
    //修改纪录
    updateRecord() {
      fetch({
        url: this.global.serverPath + 'sys/api/Menu/PutMenuTemplate',
        // url: '../static/data/sysMagData/UnResolvedAlarm-stuts.json',
        method: 'put',
        params: {
          MenutemplateId: this.form.TemplateId,
          MenuTemplateName: this.form.TemplateName,
          MenuType: this.form.MenuType,
        },
      })
        .then((res) => {
          if (res.data.Code == -1 && res.data.Message) {
            this.$notify.info({
              title:'消息',
              message: res.data.Message,
            })
            this.getRecords()
            return
          }
          this.$notify.info({
            title: '消息',
            message: '保存成功',
          })
          this.getRecords()
        })
        .catch((err) => {
          this.$notify.info({
            title:'消息',
            message: err.data.Message,
          })
          this.getRecords()
        })
    },
    //删除纪录
    deleteRecord(row) {
      this.$confirm('您确定删除吗？')
        .then((_) => {
          fetch({
            url: this.global.serverPath + 'sys/api/MenuTemplates',
            // url: '../static/data/sysMagData/UnResolvedAlarm-stuts.json',
            method: 'delete',
            params: {
              menuTemplateId: row.TemplateId,
            },
          })
            .then((res) => {
              if (res.data.Code == -1 && res.data.Message) {
                this.$notify.info({
                  title:'消息',
                  message: res.data.Message,
                })
                this.getRecords()
                return
              }
              this.$notify.info({
                title:'消息',
                message:'删除成功',
              })
              this.getRecords()
            })
            .catch((err) => {
              this.$notify.info({
                title: '消息',
                message: err.data.Message,
              })
              this.getRecords()
            })
        })
        .catch((_) => {})
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/mixin.scss';
// 编辑按钮
.el-icon-edit{
  cursor: pointer;
}
.el-icon-delete{
  cursor: pointer;
}
.el-icon-r10{
  margin-right: 10px;
}
.value-style{
	@include font_color("font2_color_white");
}
@media only screen and (max-aspect-ratio: 1920/920) {

}
@media only screen and (min-aspect-ratio: 1920/1000) {

}
</style>
