<template>
  <div class="UserManage">
    <el-row type="flex" justify="space-between" class="row_bottom">
      <el-col :span="21">
        <span class="span-total">共 {{total}} 条数据</span>
      </el-col>
      <el-col :span="3" class="buttons">
        <el-button type="success" size="small" @click="handleAddUserInfo()">添加用户信息</el-button>
      </el-col>
    </el-row>

    <el-dialog title="新增用户信息" class="comDialogTop" :visible.sync="addFormVisible" width="30%" :modal="false">
      <el-form :model="form" class="FilterCriteria" ref="form" :rules="rules" label-width="120px" 
      v-if="addFormVisible">
        <el-row :gutter="0">
          <el-col :span="24">
            <el-form-item label="姓名" prop="RealName">
              <el-input v-model="form.RealName" size="small" style="width:80%"></el-input>
            </el-form-item>
            <el-form-item label="电子邮件" prop="Email">
              <el-input v-model="form.Email" size="small" style="width:80%"></el-input>
            </el-form-item>
            <el-form-item label="手机号码" prop="MobileNo">
              <el-input v-model="form.MobileNo" size="small" style="width:80%"></el-input>
            </el-form-item>
            <el-form-item label="职能部门" prop="Department">
              <el-input v-model="form.Department" size="small" style="width:80%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer buttons">
        <el-button type="success" size="small" @click="addFormVisible = false">取消</el-button>
        <el-button type="success" size="small" @click="handleAddConfirm">保存</el-button>
      </div>
    </el-dialog>
    <el-table
      empty-text="-"
      v-loading="loading"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(31,41,48,0)"
      :fit="true"
      :stripe="true"
      :data="tableData"
      class="table_height"
      :header-cell-style="{ 'background':BackgroundColors}"
    >
      <el-table-column type="index" label="序号" v-if="false"></el-table-column>
      <el-table-column v-if="true" prop="UserNo" label="用户No"></el-table-column>
      <!-- <el-table-column sortable label="用户ID" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>{{scope.row.UserId}}</span>
        </template>
      </el-table-column> -->
      <!-- <el-table-column label="用户名" prop="UserName" sortable></el-table-column> -->
      <el-table-column label="真实姓名" prop="RealName"></el-table-column>
      <el-table-column label="创建时间" prop="CreatedTime" sortable></el-table-column>
      <el-table-column label="用户状态" prop="UserStatus" sortable>
        <template slot-scope="scope">
          <span v-if="scope.row.UserStatus == '0'">未激活</span>
          <span v-else-if="scope.row.UserStatus == '1'">已激活</span>
          <span v-else-if="scope.row.UserStatus == '2'">已删除</span>
        </template>
      </el-table-column>
      <el-table-column label="最后登录时间" prop="LastLoginTime" sortable></el-table-column>
      <!-- <el-table-column label="最后登录IP" prop="LastloginIp"></el-table-column> -->
      <!-- <el-table-column label="是否微信登录" prop="WechatStatus">
        <template slot-scope="scope">
          <span v-if="scope.row.WechatStatus">是</span>
          <span v-if="!scope.row.WechatStatus">否</span>
        </template>
      </el-table-column> -->
      <el-table-column label="操作">
        <template slot-scope="scope">
          <i class="el-icon-edit el-icon-r10" @click="handleUpdate_onClick(scope.row)"></i>
          <i class="el-icon-delete el-icon-r10" @click="handleDelete(scope.row)"></i>
          <el-button type="success" size="mini" @click="handleUserRoles(scope.row)">设置用户角色</el-button>
        </template>
      </el-table-column>
      <template slot="empty" v-if="!loading">
        <img :src="empty02" />
        <div>暂无数据</div>
      </template>
    </el-table>
    <!-- 设置用户角色 -->
    <el-dialog class="comDialogTop"
      title="设置用户角色"
      :visible.sync="setYhjsVisible"
      width="25%"
      :modal="false"
      :close-on-click-modal="false"
    >
    <el-form
     class="FilterCriteria"
      :model="jsform" 
        ref="jsform"
        :rules="jsRules"
        label-width="120px"
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(31,41,48,0)">
        <div v-for="(item,index) in formlist" :key="index">
          <el-form-item label="所属事业部" prop="AgencyId">
            <el-select
              v-model="item.AgencyId"
              placeholder="请选择"
              @change="selected($event,index)"
              filterable
              size="small"
              style="width:80%"
            >
              <el-option
                v-for="itn in item.AgencyTree"
                :key="itn.AgencyId"
                :label="itn.AgencyName"
                :value="itn.AgencyId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="角色" prop="RoleIdList">
            <el-select
              v-model="item.RoleIdList"
              placeholder="请选择"
              filterable
              size="small"
              multiple
              collapse-tags
              style="width:80%"
            >
              <el-option
                v-for="itm in item.RoleIdOption"
                :key="itm.RoleId"
                :label="itm.RoleName"
                :value="itm.RoleId"
              ></el-option>
            </el-select>
            <i v-if="formlist.length && index == formlist.length-1" 
            @click="handleFormAdd"
            class="el-icon-plus font_add"></i>
            <i v-if="formlist.length && formlist.length>1 && index == formlist.length-1" 
            @click="handleFormDele"
            class="el-icon-minus font_add"></i>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" size="small" @click="setYhjsVisible = false">取消</el-button>
        <el-button type="success" size="small" @click="handleSetYhjs('jsform')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改用户角色 -->
    <el-dialog class="comDialogTop"
      title="修改"
      :visible.sync="editYhjsVisible"
      width="50%"
      :modal="false"
      :close-on-click-modal="false"
    >
    <el-form
     class="FilterCriteria"
      :model="editform" 
        ref="editform"
        :rules="jsRules"
        label-width="120px"
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(31,41,48,0)">
        <el-row :gutter="0">
          <el-col :span="12">
            <!-- <el-form-item label="用户ID" prop="UserId">
                <el-input v-model="editform.UserId" style="width:100%" :disabled=true></el-input>
            </el-form-item> -->
            <el-form-item label="姓名" prop="RealName">
                <el-input v-model="editform.RealName" style="width:100%"></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="Sex">
              <el-switch
                v-model="editform.Sex"
                active-text="女"
                inactive-text="男"
                active-color="#13ce66"
                inactive-color="#2bcef8"
                :active-value="1"
                :inactive-value="0"
                >
              </el-switch>
            </el-form-item>
            <!-- <el-form-item label="地址" prop="Country">
                <el-input v-model="editform.Country" placeholder="国家" style="width:100%"></el-input>
                <el-input v-model="editform.Province" placeholder="省" style="width:100%"></el-input>
                <el-input v-model="editform.City" placeholder="城市" style="width:100%"></el-input>
            </el-form-item> -->
            <!-- <el-form-item label="工作单位" prop="Company">
                <el-input v-model="editform.Company" style="width:100%"></el-input>
            </el-form-item> -->
            <el-form-item label="电子邮件" prop="Email">
                <el-input v-model="editform.Email" style="width:100%"></el-input>
            </el-form-item>
            <el-form-item label="创建时间">
                <el-input v-model="editform.CreatedTime" style="width:100%"></el-input>
            </el-form-item>
            <el-form-item label="最后登录时间">
                <el-input v-model="editform.LastLoginTime" style="width:100%"></el-input>
            </el-form-item>
            <!-- <el-form-item label="最后登录IP">
                <el-input v-model="editform.LastLoginIp" style="width:100%"></el-input>
            </el-form-item> -->
          </el-col>
          <el-col :span="12">
            <!-- <el-form-item label="用户昵称">
                <el-input v-model="editform.UserName" style="width:100%"></el-input>
            </el-form-item> -->
            <el-form-item label="手机号码">
                <el-input v-model="editform.MobileNo" style="width:100%"></el-input>
            </el-form-item>
            <el-form-item label="职能部门">
                <el-input v-model="editform.Department" style="width:100%"></el-input>
            </el-form-item>
            <!-- <el-form-item label="用户状态">
                <el-input v-model="editform.UserStatus" style="width:100%"></el-input>
            </el-form-item> -->
          </el-col>
        </el-row>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" size="small" @click="editYhjsVisible = false">取消</el-button>
        <el-button type="success" size="small" @click="handleEditUpdate('editform')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  CreateUser,
  GetUserList,
  GetRoleListByAgencyId,
  GetTenantList,
  SetSystemUserRole,
  GetSystemUserRoleList,
  GetUserInfoByUserNo,
  DeleteSystemUser,
  UpdateSystemUser,

} from "@/api/menuRole"; //页面接口
import empty02 from '@/assets/icons/empty02.png'

export default {
  components: {},
  data() {
    return {
      empty02: empty02,
      form: {
        RealName:null,
        Email:null,
        MobileNo:null,
        Department:null,
      },
      addFormVisible: false,

      total: 0,
      AgencyId:null,
      RoleId:null,
      loading: true,
      tableData: [],
      rules: {
        RealName: [{ required: true, message: '不能为空', trigger: 'blur' }],
        Email: [{ required: true, message: '不能为空', trigger: 'blur' }],
        // Company: [{ required: true, message: '不能为空', trigger: 'blur' }],
        // Businesss: [{ required: true, message: '不能为空', trigger: 'blur' }],
        
        MobileNo: [{ required: true, message: '不能为空', trigger: 'blur' }],
        Department: [{ required: true, message: '不能为空', trigger: 'blur' }],
      },

      BackgroundColors:sessionStorage.getItem('BgColorType')=='white'?'#f9f9f9':'#2a343b',
      
      jsform:{
        AgencyId:null,
        RoleIdList:null,
      },
      AgencyId: null,
      RoleId: null,

      jsRules:{
        AgencyId: [{ required: true, message: '不能为空', trigger: 'blur' }],
        RoleIdList: [{ required: true, message: '不能为空', trigger: 'blur' }],
      },
      setYhjsVisible:false,

      formlist:[
      ],
      AgencySelectTree:[],
      editYhjsVisible:false,
      editform:{

      },
    }
  },
  computed: {},
  watch: {
    '$store.state.testTxt.tips'(newVal,oldVal){
			if(newVal=='white'){
				this.BackgroundColors = '#f9f9f9';
			}else{
				this.BackgroundColors = '#2a343b';
			}
		},
  },
  mounted() {

  },
  methods: {
    handleAddUserInfo() {
      this.form = {
        RealName: '',
        Email: '',
        MobileNo: '',
        Department: '',
      }
      this.addFormVisible = true
    },
    handleAddConfirm() {
      this.$refs['form'].validate((valid) => {
        if (!valid) {
          return
        } else {
          this.addRecord()
          this.addFormVisible = false
        }
      })
    },
    initAgencyList(){
      GetTenantList({

      })
      .then((res) => {
        let obj = res.Content;
        if(res.Code==-1) {
          this.$notify.info({
            title:'消息',
            message: '所属事业部查询失败'
          });
          return;
        }
        // this.AgencyTree = obj
        this.formlist = [{
          UserNo: this.UserNo,
          AgencyId:null,
          AgencyTree:obj,
          RoleIdList:[],
          RoleIdOption:[]
        }]
      })
      .catch((err) => {

      });
    },
    //获得列表
    getRecords() {
      this.loading = true
      this.total = 0
      GetUserList({

      }).then((res) => {
        let obj = res.Content;
        if(res.Code==-1) {
          this.$notify.info({
            title:'消息',
            message: '用户列表查询失败'
          });
          return;
        }
        this.total = obj.length
        this.tableData = obj
        this.loading = false
      })
      .catch((err) => {

      });
    },
    //增加
    addRecord() {
      CreateUser({
        realName: this.form.RealName,
        email: this.form.Email,
        mobileNo: this.form.MobileNo,
        department: this.form.Department
      })
      .then((res) => {
        // let obj = res.Content;
        if(res.Code==-1) {
          this.$notify.info({
            title:'消息',
            message: '列表查询失败'
          });
          return;
        }
        this.$notify.info({
          title:'消息',
          message: '用户添加成功'
        });
        this.getRecords()
      })
      .catch((err) => {

      });
    },
    // 所属事业部
    handleGetTenant(isNewStu){
      GetTenantList({

      })
      .then((res) => {
        let obj = res.Content;
        if(res.Code==-1) {
          this.$notify.info({
            title:'消息',
            message: '所属事业部查询失败'
          });
          return;
        }
        if (isNewStu) {
          this.formlist[this.formlist.length -1].AgencyTree = obj;
          this.$forceUpdate()
        }else{//某一项事业部回显  请求对应角色下拉
          this.AgencySelectTree = obj;
          for (let i = 0; i < this.formlist.length; i++) {
            this.formlist[i].AgencyTree = obj;
            this.$forceUpdate()
          }
        }
      })
      .catch((err) => {

      });
    },
    // 事业部对应角色
    handleGetRoleByAgencyId(id,index){
      GetRoleListByAgencyId({
        AgencyId: id,
      })
      .then((res) => {
        let obj = res.Content;
        if(res.Code==-1) {
          this.$notify.info({
            title:'消息',
            message: '角色查询失败'
          });
          return;
        }
        this.formlist[index].RoleIdOption = obj;
        this.formlist[index].RoleIdList = null;
        this.$forceUpdate()
      })
      .catch((err) => {

      });
    },
    selected(id,index) {
      // console.log(id,'事业部----',index);
      this.formlist[index].AgencyId = id;
      this.handleGetRoleByAgencyId(id,index)
    },
    // 设置用户角色
    handleUpdate_onClick(row){
      this.editYhjsVisible = true
      this.handleUserInfoByUserNo(row)
    },
    handleUserInfoByUserNo(row){
      GetUserInfoByUserNo({
        UserNo: row.UserNo
      }).then((res) => {
        let obj = res.Content;
        if(res.Code==-1) {
          this.$notify.info({
            title:'消息',
            message: '获取用户详情失败'
          });
          return;
        }
        this.editform = Object.assign({},obj)
      })
      .catch((err) => {

      });
    },
    // 删除用户信息
    handleDelete(row){
      this.$confirm('您确定删除吗？').then((_) => {
        DeleteSystemUser({
            UserNo: row.UserNo
          }).then((res) => {
            if(res.Code==-1) {
              this.$notify.info({
                title:'消息',
                message: '删除用户失败'
              });
              return;
            }
            this.$notify.info({
              title:'消息',
              message: '删除用户成功'
            });
            this.editYhjsVisible = false;
            this.getRecords()
          })
          .catch((err) => {

          });
        })
        .catch((err) => {})
    },
    // 修改用户信息
    handleEditUpdate(){
      UpdateSystemUser({
        ...this.editform
      }).then((res) => {
        if(res.Code==-1) {
          this.$notify.info({
            title:'消息',
            message: '修改用户信息失败'
          });
          return;
        }
        this.$notify.info({
          title:'消息',
          message: '修改用户信息成功'
        });
        this.editYhjsVisible = false;
        this.getRecords()
      })
      .catch((err) => {

      });
    },
    handleUserRoles(row){
      this.setYhjsVisible = true;
      this.UserNo = row.UserNo;
      this.handleGetUserRoleList()
    },
    // 查询用户角色列表
    handleGetUserRoleList(){
      GetSystemUserRoleList({
        UserNo: this.UserNo,
      })
      .then((res) => {
        let obj = res.Content;
        if(res.Code==-1) {
          this.$notify.info({
            title:'消息',
            message: '查询用户角色失败'
          });
          return;
        }
        if (obj.length) {
          this.formlist = obj;
          for (let i = 0; i < this.formlist.length; i++) {
            let ele = this.formlist[i];
            GetRoleListByAgencyId({//循环调用 角色下拉
              AgencyId: ele.AgencyId,
            })
            .then((res) => {
              let obj = res.Content;
              ele.RoleIdOption = obj
              this.$forceUpdate()
            })
            .catch((err) => {

            });
          }
          // console.log(this.formlist,'测试------');
          this.handleGetTenant()//回显事业部选项
        }else{
          this.initAgencyList()//查询事业部列表
        }
      })
      .catch((err) => {

      });
    },
    // 设置用户角色
    handleSetYhjs(){
      for (let i = 0; i < this.formlist.length; i++) {
        let ele = this.formlist[i];
        delete ele.AgencyTree;
        delete ele.RoleIdOption;
      }
      SetSystemUserRole(
        this.formlist
      )
      .then((res) => {
        if(res.Code==-1) {
          this.$notify.info({
            title:'消息',
            message: '设置用户角色失败'
          });
          return;
        }
        this.$notify.info({
          title:'消息',
          message: '设置用户角色成功'
        });
        this.setYhjsVisible = false;
        this.getRecords()
      })
      .catch((err) => {

      });
    },
    handleFormAdd(){
      this.formlist.push({
        AgencyId:null,
        RoleIdList:[],
        UserNo: this.UserNo
      })
      this.handleGetTenant(true)
    },
    handleFormDele(){
      this.formlist.pop()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/mixin.scss';
.table_height >>> .el-table--scrollable-x .el-table__body-wrapper {
  overflow-x: auto !important;
}
.el-icon-r10{
  margin-right: 10px;
}

// 编辑按钮
.el-icon-edit{
  cursor: pointer;
}
.el-icon-delete{
  cursor: pointer;
}
.el-icon-r{
  margin-right: 10px;
}
.el-form-item{
  margin-bottom: 15px;
}
.font_add{
  font-size: 16px;
  cursor: pointer;
  margin: 7px 0 0 10px;
  @include font_color("font2_color_white");
}
@media only screen and (max-aspect-ratio: 1920/920) {

}
@media only screen and (min-aspect-ratio: 1920/1000) {

}
</style>
