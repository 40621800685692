<template>
  <div>
    <el-row type="flex" justify="space-between" class="row_bottom">
      <el-col :span="22">
        <span class="span-total">共 {{total}} 条数据</span>
      </el-col>
      <el-col :span="5" class="buttons">
        <el-button type="success" size="small" @click="handleAdd_onClick()">新增</el-button>
      </el-col>
    </el-row>

    <el-dialog title="新增" class="comDialogTop" :visible.sync="addFormVisible" width="25%" :modal="false">
      <el-form :model="form" class="FilterCriteria" ref="form" :rules="rules" label-width="120px">
        <el-form-item label="菜单名称" prop="Name">
          <el-input v-model="form.Name" size="small" style="width:80%"></el-input>
        </el-form-item>
        <el-form-item label="菜单路由" prop="Route">
          <el-input v-model="form.Route" size="small" style="width:80%"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer buttons">
        <el-button type="success" size="small" @click="addFormVisible = false">取消</el-button>
        <el-button type="success" size="small" @click="handleAdd">保存</el-button>
      </div>
    </el-dialog>

    <el-table
      empty-text="-"
      v-loading="loading"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(31,41,48,0)"
      :fit="true"
      :stripe="true"
      class="table_height"
      :data="tableData"
      :header-cell-style="{ 'background':BackgroundColors}"
    >
      <el-table-column type="index" label="序号" v-if="false"></el-table-column>
      <el-table-column sortable prop="MenuId" label="菜单ID"></el-table-column>
      <el-table-column label="菜单名称" prop="Name" sortable></el-table-column>
      <el-table-column label="路由" prop="Route"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <i class="el-icon-edit el-icon-r10" @click="handleUpdate_onClick(scope.row)"></i>
          <i class="el-icon-delete" @click="handleDelete(scope.row)"></i>
        </template>
      </el-table-column>

      <slot></slot>
      <template slot="empty" v-if="!loading">
        <img :src="empty01" />
        <div>暂无数据</div>
      </template>
    </el-table>

    <el-dialog title="修改" class="comDialogTop" :visible.sync="updateFormVisible" width="25%" :modal="false">
      <el-form :model="form" class="FilterCriteria" ref="form" :rules="rules" label-width="120px">
        <el-form-item label="菜单ID" prop="MenuId">
          <span class="value-style">{{form.MenuId}}</span>
        </el-form-item>
        <el-form-item label="菜单名称" prop="Name">
          <el-input v-model="form.Name" size="small" style="width:80%"></el-input>
        </el-form-item>
        <el-form-item label="菜单路由" prop="Route">
          <el-input v-model="form.Route" size="small" style="width:80%"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer buttons">
        <el-button type="success" size="small" @click="updateFormVisible = false">取消</el-button>
        <el-button type="success" size="small" @click="handleUpdate()">提交修改</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetMenuList,
  AddMenu,
  ModifyMenu,
  DeleteMenu,
} from "@/api/menuRole"; //页面接口
import empty01 from '@/assets/icons/empty02.png'

export default {
  components: {},
  data() {
    return {
      empty01: empty01,
      tableData: [
      ],

      form: {
        MenuId: null,
        MenuType: null,
        Name: null,
        Route:null,
        Icon:null,
      },
      total: 0,
      loading: true,
      addFormVisible: false,
      updateFormVisible: false,

      rules: {
        Name: [{ required: true, message: '请输入菜单名称', trigger: 'blur' }],
        Route: [{ required: true, message: '请输入菜单路由', trigger: 'blur' }],
      },

      BackgroundColors:sessionStorage.getItem('BgColorType')=='white'?'#f9f9f9':'#2a343b',

    }
  },
  watch: {
    '$store.state.testTxt.tips'(newVal,oldVal){
			if(newVal=='white'){
				this.BackgroundColors = '#f9f9f9';
			}else{
				this.BackgroundColors = '#2a343b';
			}
		},
  },
  mounted() {

  },
  methods: {
    //获得列表
    getMenuRecords() {
      this.loading = true
      this.total = 0
      GetMenuList({
        
      }).then((res) => {
        let obj = res.Content;
        if(res.Code==-1) {
          this.$notify.info({
            title:'消息',
            message: '菜单列表查询失败'
          });
          return;
        }
        this.total = obj.length
        this.tableData = obj
        this.loading = false
      })
      .catch((err) => {

      });
    },
    handleAdd_onClick() {
      this.form = {
        MenuId: null,
        MenuType: null,
        Name: null,
        Route:null,
        Icon:null
      }
      this.addFormVisible = true
    },
    handleUpdate_onClick(row) {
      this.form = Object.assign({},row)
      this.updateFormVisible = true
    },
    handleAdd() {
      this.$refs['form'].validate((valid) => {
        if (!valid) {
          return
        } else {
          // this.addRecord()
          AddMenu({
            ...this.form
          }).then((res) => {
            if(res.Code==-1) {
              this.$notify.info({
                title:'消息',
                message: '新增菜单失败'
              });
              return;
            }
            this.$notify.info({
              title:'消息',
              message: '新增菜单成功'
            });
            this.getMenuRecords()
            this.addFormVisible = false
          })
          .catch((err) => {

          });
        }
      })
    },
    handleUpdate() {
      this.$refs['form'].validate((valid) => {
        if (!valid) {
          return
        } else {
          ModifyMenu({
            ...this.form
          }).then((res) => {
            if(res.Code==-1) {
              this.$notify.info({
                title:'消息',
                message: '修改菜单失败'
              });
              return;
            }
            this.$notify.info({
              title:'消息',
              message: '修改菜单成功'
            });
            this.getMenuRecords()
            this.updateFormVisible = false
          })
          .catch((err) => {

          });
        }
      })
    },
    handleDelete(row) {
      this.$confirm('您确定删除吗？').then((_) => {
        DeleteMenu({
          MenuId: row.MenuId
        }).then((res) => {
          if(res.Code==-1) {
            this.$notify.info({
              title:'消息',
              message: '删除菜单失败'
            });
            return;
          }
          this.$notify.info({
            title:'消息',
            message: '删除菜单成功'
          });
          this.getMenuRecords()
        })
        .catch((err) => {

        });
      })
      .catch((_) => {})
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/mixin.scss';
// 编辑按钮
.el-icon-edit{
  cursor: pointer;
}
.el-icon-delete{
  cursor: pointer;
}
.el-icon-r10{
  margin-right: 10px;
}
.value-style{
	@include font_color("font2_color_white");
}
.el-form-item{
  margin-bottom: 15px;
}
@media only screen and (max-aspect-ratio: 1920/920) {

}
@media only screen and (min-aspect-ratio: 1920/1000) {

}
</style>
