<template>
  <div>
    <el-row type="flex" justify="space-between" class="row-bottom">
      <el-col :span="22">
        <!-- <span class="span-total">{{$t('common.共')}} {{total}} {{$t('common.条数据')}}</span> -->
      </el-col>
      <el-col :span="5" class="buttons">
        <el-button type="success" size="small" @click="handleAdd_onClick()">新增</el-button>
        <!-- <Upload action="" :before-upload="handleBeforeUpload" accept=".xls, .xlsx">
           <el-button type="primary" size="small" icon="ios-cloud-upload-outline" :loading="uploadLoading" @click="handleUploadFile">上传文件</el-button>
        </Upload>-->
      </el-col>
    </el-row>

    <div class="container">
      <el-table
        :data="treeData"
        row-key="MenuId"
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(31,41,48,0)"
        default-expand-all
        @row-click="rowclick"
        empty-text="-"
        :fit="true"
        :stripe="true"
        height="300"
        class="table"
        :header-cell-style="{ 'background':BackgroundColors}"
      >
        <el-table-column type="index" label="序号" v-if="false"></el-table-column>
        <el-table-column v-if="false" prop="MenuId" label="菜单ID"></el-table-column>
        <el-table-column prop="MenuName" label="菜单名称"></el-table-column>
        <el-table-column prop="MenuRoute" label="菜单路由"></el-table-column>
        <!-- <el-table-column label="登录默认" width="100" align="center">
          <template slot-scope="scope">
            <el-radio
              v-model="LoginDef"
              :label="scope.row.MenuId"
              @change.native="getCurrentRow(scope.row)"
            >
              {{''}}
            </el-radio>
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="切换默认" width="100" align="center">
          <template slot-scope="scope">
            <el-radio
              v-model="ChangeDef"
              :label="scope.row.MenuId"
              @change.native="getCurrentRow2(scope.row)"
            >
            {{''}}
          </el-radio>
          </template>
        </el-table-column> -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" class="el-icon-r10" type="success" @click="handleAdd_onClick(scope.row)">新增</el-button>
            <i class="el-icon-edit el-icon-r10" @click="handleUpdate_onClick(scope.row)"></i>
            <i class="el-icon-delete" @click="handleDelete(scope.row)"></i>
          </template>
        </el-table-column>
        <template slot="empty" v-if="!loading">
          <img :src="empty01" />
          <div>暂无数据</div>
        </template>
      </el-table>

      <el-dialog title="新增" :visible.sync="addFormVisible" width="25%" :modal="false">
        <el-form :model="form" class="FilterCriteria" ref="form" :rules="rules" label-width="120px">
          <el-form-item label="ParentId" v-show="false">
            <el-input v-model="form.ParentId" size="small"></el-input>
          </el-form-item>
          <el-form-item label="菜单项" prop="MenuId" style="width:160%">
            <template slot-scope="scope">
              <el-select v-model="form.MenuId" placeholder="请选择"  size="small" filterable style="width:90%">
                <el-option
                  v-for="it in menusData"
                  :key="it.MenuId"
                  :label="`${it.Name}- ${it.Route}`"
                  :value="it.MenuId"
                ></el-option>
              </el-select>
            </template>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer buttons">
          <el-button type="success" size="small" @click="addFormVisible = false">取消</el-button>
          <el-button type="success" size="small" @click="handleAdd()">保存</el-button>
        </div>
      </el-dialog>

      <el-dialog title="修改" :visible.sync="updateFormVisible" width="25%" :modal="false">
        <el-form :model="form" class="FilterCriteria" ref="form" :rules="rules" label-width="120px">
          <el-form-item label="ParentId" v-show="false">
            <el-input v-model="form.ParentId" size="small"></el-input>
          </el-form-item>
          <el-form-item label="菜单项" prop="MenuId">
            <template slot-scope="scope">
              <el-select v-model="form.MenuId" size="small" placeholder="请选择" filterable >
                <el-option style="width:20vw"
                  v-for="it in menusData"
                  :key="it.MenuId"
                  :label="`${it.Name}- ${it.Route}`"
                  :value="it.MenuId"
                ></el-option>
              </el-select>
            </template>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer buttons">
          <el-button type="success" size="small" @click="updateFormVisible = false">取消</el-button>
          <el-button type="success" size="small" @click="handleUpdate()">提交修改</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import { fetch } from '@/axios/fetch'
import empty01 from '@/assets/icons/empty02.png'
import { isEmpty, replaceKeyName3 } from '@/common/js/base.js'
import {
  GetMenuList,
  GetMenuTemplateDetailList,
  AddMenuTemplateDetail,
  ModifyMenuTemplateDetail,
  DeleteMenuTemplateDetail,
} from "@/api/menuRole"; //页面接口

export default {
  components: {},
  props: ['MenuTemplateId'],
  data() {
    return {
      empty01: empty01,
      form: {
        MenuId:null,
        MenuName:null,
        MenuRoute:null,
        MenuTemplateId:null,
        ParentId:null,
        TemplateDetailId:null,
      },
      NewMenuId:'',
      treeData: [],
      menusData: [],

      LoginDef:'',
      ChangeDef:'',

      addFormVisible: false,
      updateFormVisible: false,
      rules: {
        MenuName: [{ required: true, message: '请输入菜单名称', trigger: 'blur' }],
        MenuId: [{ required: true, message: '请选择菜单项', trigger: 'blur' }],
      },
      loading: true,
      BackgroundColors:sessionStorage.getItem('BgColorType')=='white'?'#f9f9f9':'#2a343b',
    }
  },
  watch: {
    '$store.state.testTxt.tips'(newVal,oldVal){
			if(newVal=='white'){
				this.BackgroundColors = '#f9f9f9';
			}else{
				this.BackgroundColors = '#2a343b';
			}
		},
  },
  mounted() {
    //初始化页面时
  },
  methods: {
    btn_save() {
      localStorage.locationTree = JSON.stringify(this.treeData)
    },
    rowclick(row, column, event) {
      try {
        event.target.parentElement
          .getElementsByClassName('el-icon-arrow-right')[0]
          .click()
      } catch (e) {
        try {
          event.target.parentElement.parentElement
            .getElementsByClassName('el-icon-arrow-right')[0]
            .click()
        } catch (e) {
          if (
            event.target.parentElement.parentElement.getElementsByClassName(
              'el-icon-arrow-right'
            )[0] != undefined
          ) {
            event.target.parentElement.parentElement
              .getElementsByClassName('el-icon-arrow-right')[0]
              .click()
          }
        }
      }
    },
    // getCurrentRow(row) {
    //   this.LoginDef = row.MenuId;
    //   this.saveDefalutMenuTemplate(1,row.MenuId);
    // },
    // getCurrentRow2(row) {
    //   this.ChangeDef = row.MenuId;
    //   this.saveDefalutMenuTemplate(2,row.MenuId);
    // },
    // saveDefalutMenuTemplate(defaultType,MenuId) {
    //   fetch({
    //     url: this.global.serverPath + 'sys/api/Menu/SaveDefalutMenuTemplate',
    //     params: {
    //       MenuTemplateId:this.MenuTemplateId,
    //       MenuId:MenuId,
    //       DefaultType:defaultType
    //     },
    //     method: 'post'
    //   })
    //     .then((res) => {
    //       if (res.data.Code == -1 && res.data.Message) {
    //         this.$notify.info({
    //           title:'消息',
    //           message: res.data.Message,
    //         })
    //         this.getDefalutMenuTemplate()
    //         return
    //       }
    //       this.$notify.info({
    //         title: '消息',
    //         message:'保存成功',
    //       })
    //       this.getDefalutMenuTemplate()
    //     })
    //     .catch((err) => {
    //       this.$notify.info({
    //         title: '消息',
    //         message: err.data.Message,
    //       })
    //       this.getDefalutMenuTemplate()
    //     })
    // },
    // getDefalutMenuTemplate() {
    //   this.loading = true
    //   this.menusData = []
    //   fetch({
    //     url: this.global.serverPath + 'sys/api/Menu/GetDefalutMenuTemplate',
    //     // url: '../static/data/sysMagData/Model.json',
    //     method: 'get',
    //     params: {
    //       MenuTemplateId:this.MenuTemplateId
    //     }
    //   })
    //     .then((res) => {
    //       let list = res.data.Content
    //       if (res.data.Code == -1 && res.data.Message) {
    //         this.$notify.info({
    //           title: '消息',
    //           message: res.data.Message,
    //         })
    //       }
    //       if (isEmpty(res.data.Content)) {
    //         this.loading = false
    //         return
    //       }
    //       this.$nextTick(()=>{
    //         for(let i=0; i<list.length;i++){
    //           if(list[i].DefaultType == 1){
    //             this.LoginDef = list[i].MenuId;
    //           }else if(list[i].DefaultType == 2){
    //             this.ChangeDef = list[i].MenuId;
    //           }
    //         }
    //       })

    //       this.loading = false
    //     })
    //     .catch((err) => {
    //       this.loading = false
    //       this.$notify.info({
    //         title:'消息',
    //         message: err.data.Message,
    //       })
    //     })
    // },
    handleAdd_onClick(row) {
      if (row) {
        this.form = {
          // ParentId: row.ParentId,
          ParentId: row.TemplateDetailId,
          MenuId: row.MenuId,
          // MenuName:row.MenuName,
          // MenuRoute:row.MenuRoute,
          MenuTemplateId:row.MenuTemplateId,//此处接口返回了列表的MenuTemplateId
          // TemplateDetailId:row.TemplateDetailId,
        }
      }else{
        this.form = {
          ParentId: 0,
          MenuId:null,
          // MenuName:null,
          // MenuRoute:null,
          MenuTemplateId: this.MenuTemplateId,
          // TemplateDetailId:null,
        }
      }
      this.addFormVisible = true
      this.$nextTick(() => {
        this.getAllMenus()
      })
    },
    handleAdd() {
      this.$refs['form'].validate((valid) => {
        if (!valid) {
          return
        } else {
          this.addRecord()
          this.addFormVisible = false
        }
      })
    },
    handleDelete(row) {
      this.deleteRecord(row)
    },
    handleUpdate_onClick(row) {
      this.updateFormVisible = true
      this.getAllMenus()
      this.$nextTick(() => {
        this.form = Object.assign({},{
          ParentId: row.ParentId,
          MenuId: row.MenuId,
          MenuTemplateId:row.MenuTemplateId,//此处接口返回了列表的MenuTemplateId
          TemplateDetailId:row.TemplateDetailId,
        })
      })
    },
    handleUpdate() {
      this.$refs['form'].validate((valid) => {
        if (!valid) {
          return
        } else {
          this.updateRecord()
          this.updateFormVisible = false
        }
      })
    },
    updateRecord() {
      ModifyMenuTemplateDetail({
        ...this.form
      }).then((res) => {
        if(res.Code==-1) {
          this.$notify.info({
            title:'消息',
            message: '菜单模板详情更新失败'
          });
          return;
        }
        this.$notify.info({
          title:'消息',
          message: '菜单模板详情更新成功'
        });
        this.getRecords()
      })
      .catch((err) => {

      });
    },
    //获得列表
    getAllMenus() {
      this.loading = true
      GetMenuList({
        
      }).then((res) => {
        let obj = res.Content;
        if(res.Code==-1) {
          this.$notify.info({
            title:'消息',
            message: '菜单查询失败'
          });
          return;
        }
        this.menusData = obj
        this.loading = false
      })
      .catch((err) => {

      });
    },
    // 详情列表
    getRecords() {
      GetMenuTemplateDetailList({
        MenuTemplateId: this.MenuTemplateId,
      }).then((res) => {
        let list = res.Content
        if(res.Code==-1) {
          this.$notify.info({
            title:'消息',
            message: '查询菜单模板详情失败'
          });
          return;
        }
        var a = JSON.stringify(list).replace(/Children/g, 'children')
        var b = JSON.parse(a)
        this.treeData = b;

        this.loading = false
      })
      .catch((err) => {

      });
    },
    // 新增
    addRecord() {
      AddMenuTemplateDetail({
        ...this.form
      }).then((res) => {
        if(res.Code==-1) {
          this.$notify.info({
            title:'消息',
            message: '菜单模板新增失败'
          });
          return;
        }
        this.$notify.info({
            title:'消息',
            message: '菜单模板新增成功'
          });
        this.getRecords()
      })
      .catch((err) => {

      });
    },
    deleteRecord(row) {
      this.$confirm('您确定删除吗？')
        .then((_) => {
          DeleteMenuTemplateDetail({
            TemplateDetailId: row.TemplateDetailId
          }).then((res) => {
            if(res.Code==-1) {
              this.$notify.info({
                title:'消息',
                message: '菜单模板删除失败'
              });
              return;
            }
            this.$notify.info({
                title:'消息',
                message: '菜单模板删除成功'
              });
            this.getRecords()
          })
          .catch((err) => {

          });
        })
      .catch((_) => {})
    },
  },
}
</script>

<style lang="scss" scoped>
// 编辑按钮
.el-icon-edit{
  cursor: pointer;
}
.el-icon-delete{
  cursor: pointer;
}
.el-icon-r10{
  margin-right: 10px;
}
@media only screen and (max-aspect-ratio: 1920/920) {
  .container {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .table {
    height: 600px !important;
    top: 0px !important;
    left: 0px !important;
  }
  .el-select {
    width: auto !important;
  }
  .container >>>.el-form .el-input {
    position: relative;
    display: inline-block;
    width: 300px !important;
}
}
@media only screen and (min-aspect-ratio: 1920/920) {
  .container {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .table {
    height: 60vh !important;
    top: 0px !important;
    left: 0px !important;
  }
  .el-select {
    width: auto !important;
  }
  .container >>>.el-form .el-input {
    position: relative;
    display: inline-block;
    width: 300px !important;
}
}
</style>
