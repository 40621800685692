<template>
  <el-card class="permission_card">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="用户" name="1">
        <UserManage ref="UserManage"></UserManage>
      </el-tab-pane>
      <el-tab-pane label="角色" name="2">
        <RoleManage ref="RoleManage"></RoleManage>
      </el-tab-pane>
      <el-tab-pane label="菜单项" name="3">
        <MenuItemManage ref="MenuItemManage"></MenuItemManage>
      </el-tab-pane>
      <el-tab-pane label="菜单模板" name="4">
        <MenuTemplateManage ref="MenuTemplateManage"></MenuTemplateManage>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
import UserManage from './components/UserManage'
import RoleManage from './components/RoleManage'
import MenuItemManage from './components/MenuItemManage'
import MenuTemplateManage from './components/MenuTemplateManage'

export default {
  components: {
    RoleManage,
    UserManage,
    MenuItemManage,
    MenuTemplateManage,
  },
  data() {
    return {
      date: '',
      activeName: '1',
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.$nextTick(() => {
      this.$refs.UserManage.getRecords()
    })
  },
  methods: {
    handleClick(tab, event) {
      if (event.target.innerText == '用户') {
        this.$refs.UserManage.getRecords()
      } else if (event.target.innerText == '角色') {
        this.$refs.RoleManage.getRoleRecords()
      } else if (event.target.innerText == '菜单项') {
        this.$refs.MenuItemManage.getMenuRecords()
      } else if (event.target.innerText == '菜单模板') {
        this.$refs.MenuTemplateManage.getMenuRecords()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/mixin.scss';
.permission_card{
  @include background_color("background_color_white");
  border: 1px solid transparent;
}
@media only screen and (max-aspect-ratio: 1920/920 ) {

}
@media only screen and (min-aspect-ratio: 1920/1000) {

}
</style>