import request from '@/utils/request'

// 查询菜单集合
export function GetMenuList(params) {
  return request({
    url: 'api/Equipment/GetMenuList',
    method: 'get',
    params
  })
}
//添加菜单
export function AddMenu(params) {
  return request({
    url: 'api/Equipment/AddMenu',
    method: 'post',
    data:params
  })
}
//修改菜单
export function ModifyMenu(params) {
  return request({
    url: 'api/Equipment/ModifyMenu',
    method: 'post',
    data:params
  })
}
//删除菜单
export function DeleteMenu(params) {
  return request({
    url: 'api/Equipment/DeleteMenu',
    method: 'post',
    data:params
  })
}


// 查询菜单模板集合
export function GetMenuTemplateList(params) {
  return request({
    url: 'api/Equipment/GetMenuTemplateList',
    method: 'get',
    params
  })
}
//新增菜单模板
export function AddMenuTemplate(params) {
  return request({
    url: 'api/Equipment/AddMenuTemplate',
    method: 'post',
    data:params
  })
}
//更新菜单模板
export function ModifyMenuTemplate(params) {
  return request({
    url: 'api/Equipment/ModifyMenuTemplate',
    method: 'post',
    data:params
  })
}
//删除菜单模板
export function DeleteMenuTemplate(params) {
  return request({
    url: 'api/Equipment/DeleteMenuTemplate',
    method: 'post',
    data:params
  })
}



// 查询菜单模板详情
export function GetMenuTemplateDetailList(params) {
  return request({
    url: 'api/Equipment/GetMenuTemplateDetailList',
    method: 'get',
    params
  })
}
//新增菜单模板详情
export function AddMenuTemplateDetail(params) {
  return request({
    url: 'api/Equipment/AddMenuTemplateDetail',
    method: 'post',
    data:params
  })
}
//更新菜单模板详情
export function ModifyMenuTemplateDetail(params) {
  return request({
    url: 'api/Equipment/ModifyMenuTemplateDetail',
    method: 'post',
    data:params
  })
}
//删除菜单模板详情
export function DeleteMenuTemplateDetail(params) {
  return request({
    url: 'api/Equipment/DeleteMenuTemplateDetail',
    method: 'post',
    data:params
  })
}



// 查询角色列表
export function GetRoleList(params) {
  return request({
    url: 'api/Equipment/GetRoleList',
    method: 'get',
    params
  })
}
//新增角色
export function AddRole(params) {
  return request({
    url: 'api/Equipment/AddRole',
    method: 'post',
    data:params
  })
}
//更新角色
export function ModifyRole(params) {
  return request({
    url: 'api/Equipment/ModifyRole',
    method: 'post',
    data:params
  })
}
//删除角色
export function DeleteRole(params) {
  return request({
    url: 'api/Equipment/DeleteRole',
    method: 'post',
    data:params
  })
}


// 查询角色关联菜单
export function GetRoleMenuList(params) {
  return request({
    url: 'api/Equipment/GetRoleMenuList',
    method: 'get',
    params
  })
}
//新增角色关联菜单
export function AddRoleMenu(params) {
  return request({
    url: 'api/Equipment/AddRoleMenu',
    method: 'post',
    data:params
  })
}
//更新角色关联菜单
export function ModifyRoleMenu(params) {
  return request({
    url: 'api/Equipment/ModifyRoleMenu',
    method: 'post',
    data:params
  })
}
//删除角色关联菜单
export function DeleteRoleMenu(params) {
  return request({
    url: 'api/Equipment/DeleteRoleMenu',
    method: 'post',
    data:params
  })
}



// 查询数据权限对象
export function GetSystemAuthObjectsList(params) {
  return request({
    url: 'api/Equipment/GetSystemAuthObjectsList',
    method: 'get',
    params
  })
}
//新增数据权限对象
export function AddSystemAuthObjects(params) {
  return request({
    url: 'api/Equipment/AddSystemAuthObjects',
    method: 'post',
    data:params
  })
}
//修改数据权限对象
export function ModifySystemAuthObjects(params) {
  return request({
    url: 'api/Equipment/ModifySystemAuthObjects',
    method: 'post',
    data:params
  })
}
//删除数据权限对象
export function DeleteSystemAuthObjects(params) {
  return request({
    url: 'api/Equipment/DeleteSystemAuthObjects',
    method: 'post',
    data:params
  })
}
//设置用户数据权限
export function SetSystemUserAuthObject(params) {
  return request({
    url: 'api/Equipment/SetSystemUserAuthObject',
    method: 'post',
    data:params
  })
}


//新增用户
export function CreateUser(params) {
  return request({
    url: 'api/System/CreateUser',
    method: 'post',
    data:params
  })
}
// 查询用户列表
export function GetUserList(params) {
  return request({
    url: 'api/System/GetUserList',
    method: 'get',
    params
  })
}
// 查询角色列表
export function GetRoleListByAgencyId(params) {
  return request({
    url: 'api/Equipment/GetRoleListByAgencyId',
    method: 'get',
    params
  })
}
//设置用户角色
export function SetSystemUserRole(params) {
  return request({
    url: 'api/Equipment/SetSystemUserRole',
    method: 'post',
    data:params
  })
}
// 查询用户角色列表
export function GetSystemUserRoleList(params) {
  return request({
    url: 'api/Equipment/GetSystemUserRoleList',
    method: 'get',
    params
  })
}
// 获取园区
export function GetTenantList(params) {
  return request({
    url: 'api/System/GetTenantList',
    method: 'get',
    params
  })
}


// 获取用户详情
export function GetUserInfoByUserNo(params) {
  return request({
    url: 'api/System/GetUserInfoByUserNo',
    method: 'get',
    params
  })
}
//删除用户角色
export function DeleteSystemUser(params) {
  return request({
    url: 'api/System/DeleteSystemUser',
    method: 'post',
    data:params
  })
}
//删除用户角色
export function UpdateSystemUser(params) {
  return request({
    url: 'api/System/UpdateSystemUser',
    method: 'post',
    data:params
  })
}


