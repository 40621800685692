<template>
  <div class="UserManage">
    <el-row type="flex" justify="space-between" class="row_bottom">
      <el-col :span="21">
        <span class="span-total">共 {{total}} 条数据</span>
      </el-col>
      <el-col :span="3" class="buttons">
        <el-button type="success" size="small" @click="handleAdd_onClick()">新增角色信息</el-button>
      </el-col>
    </el-row>

    <el-table
      empty-text="-"
      v-loading="loading"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(31,41,48,0)"
      :fit="true"
      :stripe="true"
      :data="tableData"
      class="table_height"
      :header-cell-style="{ 'background':BackgroundColors}"
    >
      <af-table-column type="index" label="序号" v-if="false"></af-table-column>
      <af-table-column prop="RoleId" label="角色ID" width="150"></af-table-column>
      <af-table-column label="角色名称" prop="RoleName" sortable></af-table-column>
      <af-table-column label="操作" width="150">
        <template slot-scope="scope">
          <i class="el-icon-edit el-icon-r10" @click="handleUpdate_onClick(scope.row)"></i>
          <i class="el-icon-delete el-icon-r10" @click="handleDelete(scope.row)"></i>
          <el-button
            size="mini"
            type="success"
            class="el-ic"
            @click="handleUpdateMenu_onClick(scope.row)"
          >指定菜单</el-button>
        </template>
      </af-table-column>

      <slot></slot>
      <template slot="empty" v-if="!loading">
        <img :src="empty01" />
        <div>暂无数据</div>
      </template>
    </el-table>
    <!-- 新增 -->
    <el-dialog title="新增角色信息" class="comDialogTop" :visible.sync="addFormVisible" width="25%" :modal="false">
      <el-form :model="addformRole" class="FilterCriteria" ref="addformRole" :rules="rules" label-width="120px">
        <el-form-item label="所属事业部" prop="AgencyId">
          <el-select style="width:80%"
            v-model="addformRole.AgencyId"
            placeholder="请选择"
            @change="TenanAddSelected"
            filterable
            size="small"
          >
            <el-option
              v-for="item in AgencyTree"
              :key="item.AgencyId"
              :label="item.AgencyName"
              :value="item.AgencyId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色名称" prop="RoleName">
          <el-input v-model="addformRole.RoleName" size="small" style="width:80%"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer buttons">
        <el-button type="success" size="small" @click="addFormVisible = false">取消</el-button>
        <el-button type="success" size="small" @click="handleAddComfirm">保存</el-button>
      </div>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog title="修改角色信息" class="comDialogTop" :visible.sync="editFormVisible" width="25%" :modal="false">
      <el-form :model="editformRole" class="FilterCriteria" ref="editformRole" :rules="rules" label-width="120px">
        <el-form-item label="角色ID：" prop="RoleId">
          <span class="value-style">{{editformRole.RoleId}}</span>
        </el-form-item>
        <el-form-item label="所属事业部" prop="AgencyId">
          <el-select style="width:80%"
            v-model="editformRole.AgencyId"
            placeholder="请选择"
            @change="TenanEditSelected"
            filterable
            size="small"
          >
            <el-option
              v-for="item in AgencyTree"
              :key="item.AgencyId"
              :label="item.AgencyName"
              :value="item.AgencyId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色名称：" prop="RoleName">
          <el-input v-model="editformRole.RoleName" size="small" style="width:80%"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer buttons">
        <el-button type="success" size="small" @click="editFormVisible = false">取消</el-button>
        <el-button type="success" size="small" @click="handleEditComfirm">保存</el-button>
      </div>
    </el-dialog>

    <!-- START 指定菜单 -->
    <el-dialog title="指定菜单" class="comDialogTop" :visible.sync="updateFormMenuVisible" 
    width="50%" :modal="false">
      <el-form :model="formMenu" class="FilterCriteria" ref="formMenu" :inline="true" :rules="rules"
      label-width="120px">
        <el-form-item label="编号:" v-show="true" label-width="50px">
          <span class="value-style">{{formMenu.RoleId}}</span>
        </el-form-item>
        <el-form-item label="角色名称:">
          <span class="value-style">{{formMenu.RoleName}}</span>
        </el-form-item>
        <el-form-item label="菜单模板:" prop="MenuTemplateId">
          <el-select size="small"
            v-model="MenuTemplateId"
            placeholder="请选择"
            collapse-tags
            style="width:100%"
          >
            <el-option
              v-for="item in MenuTemplates"
              :key="item.MenuTemplateId"
              :label="item.MenuTemplateName"
              :value="item.MenuTemplateId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <el-table
        ref="menuTable"
        empty-text="-"
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(31,41,48,0)"
        :fit="true"
        :stripe="true"
        :data="tableDataMenu"
        row-key="MenuId"
        default-expand-all
        height="300"
        :header-cell-style="{ 'background':BackgroundColors}"
      >
        <el-table-column prop="MenuName" label="菜单"></el-table-column>
        <template slot="empty" v-if="!loading">
          <img :src="empty01" />
          <div>暂无数据</div>
        </template>
      </el-table>

      <div slot="footer" class="dialog-footer buttons">
        <el-button type="success" size="small" @click="updateFormMenuVisible = false">取消</el-button>
        <el-button type="success" size="small" @click="handleUpdateMenu()">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { isEmpty, getBrowserLang } from '@/common/js/base.js'
import empty01 from '@/assets/icons/empty02.png'
import {
  GetRoleList,
  AddRole,
  ModifyRole,
  DeleteRole,

  GetRoleMenuList,
  AddRoleMenu,
  GetMenuTemplateList,
  GetMenuTemplateDetailList,
  GetTenantList,
} from "@/api/menuRole"; //页面接口

export default {
  components: {},
  data() {
    return {
      empty01: empty01,
      total:0,
      addformRole: {
        RoleName: '',
        AgencyId:null,
      },
      editformRole: {
        RoleId: '',
        RoleName: '',
        AgencyId:null,
      },
      loading: true,
      addFormVisible: false,
      editFormVisible:false,
      rules: {
        RoleName: [{ required: true, message:'不能为空', trigger: 'blur' }],
        AgencyId: [{ required: true, message:'不能为空', trigger: 'change' }],
      },
      tableData:[],

      BackgroundColors:sessionStorage.getItem('BgColorType')=='white'?'#f9f9f9':'#2a343b',
      formMenu: {
        RoleId: '',
        RoleName: '',
      },
      updateFormMenuVisible: false,
      MenuTemplates: [],
      tableDataMenu: [],
      MenuTemplateId:null,
      RowRoleId:null,
      AgencyTree: [],
    }
  },
  watch: {
    '$store.state.testTxt.tips'(newVal,oldVal){
			if(newVal=='white'){
				this.BackgroundColors = '#f9f9f9';
			}else{
				this.BackgroundColors = '#2a343b';
			}
		},
    MenuTemplateId(newVal,oldVal){
      if (newVal) {
        this.getRoleMenus(newVal)
      }
    },
  },
  mounted() {
    
  },
  methods: {
    //获得列表
    getRoleRecords() {
      this.loading = true
      this.total = 0
      GetRoleList({

      }).then((res) => {
        let obj = res.Content;
        if(res.Code==-1) {
          this.$notify.info({
            title:'消息',
            message: '角色列表查询失败'
          });
          return;
        }
        this.total = obj.length
        this.tableData = obj
        this.loading = false
      })
      .catch((err) => {

      });
    },
    handleAdd_onClick() {
      this.addformRole = {
        RoleName: '',
        AgencyId: null,
      }
      this.addFormVisible = true
      this.handleGetTenant()
    },
    handleAddComfirm() {
      this.$refs['addformRole'].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          AddRole({
            agencyId: this.addformRole.AgencyId,
            roleName: this.addformRole.RoleName
          }).then((res) => {
            if(res.Code==-1) {
              this.$notify.info({
                title:'消息',
                message: '新增角色失败'
              });
              return;
            }
            this.$notify.info({
              title:'消息',
              message: '新增角色成功'
            });
            this.addFormVisible = false
            this.getRoleRecords()
          })
          .catch((err) => {

          });
        }
      })
    },
    handleUpdate_onClick(row) {
      this.editformRole = Object.assign({},row)
      this.editFormVisible = true
      this.handleGetTenant()
    },
    handleEditComfirm() {
      this.$refs['editformRole'].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          ModifyRole({
            agencyId: this.editformRole.AgencyId,
            roleId: this.editformRole.RoleId,
            roleName: this.editformRole.RoleName
          }).then((res) => {
            if(res.Code==-1) {
              this.$notify.info({
                title:'消息',
                message: '修改角色失败'
              });
              return;
            }
            this.$notify.info({
              title:'消息',
              message: '修改角色成功'
            });
            this.editFormVisible = false
            this.getRoleRecords()
          })
          .catch((err) => {

          });
        }
      })
    },
    handleDelete(row) {
      this.$confirm('您确定删除吗？').then((_) => {
        DeleteRole({
            RoleId: row.RoleId
          }).then((res) => {
            if(res.Code==-1) {
              this.$notify.info({
                title:'消息',
                message: '删除角色失败'
              });
              return;
            }
            this.$notify.info({
              title:'消息',
              message: '删除角色成功'
            });
            this.getRoleRecords()
          })
          .catch((err) => {

          });
        })
        .catch((err) => {})
    },

    handleUpdateMenu_onClick(row) {
      let record = JSON.stringify(row)
      this.formMenu = JSON.parse(record)
      this.updateFormMenuVisible = true

      this.RowRoleId = row.RoleId//用于回显MenuTemplateId
      this.getMenuTemplateList() //下拉
    },
    getMenuTemplateList() {
      this.MenuTemplates = []
      GetMenuTemplateList({
        
      }).then((res) => {
        let obj = res.Content;
        if(res.Code==-1) {
          this.$notify.info({
            title:'消息',
            message: '菜单模板列表查询失败'
          });
          return;
        }
        this.MenuTemplates = obj;
        this.getRoleMenuTemplateId(this.RowRoleId)//回显当前行菜单
      })
      .catch((err) => {

      });
    },
    getRoleMenuTemplateId(id){
      GetRoleMenuList({
        RoleId: id
      }).then((res) => {
        let obj = res.Content;
        if(res.Code==-1) {
          this.$notify.info({
            title:'消息',
            message: '查询角色关联菜单失败'
          });
          return;
        }
        this.MenuTemplateId = obj.MenuTemplateId//回显
      })
      .catch((err) => {

      });
    },
    //获取角色指定菜单
    getRoleMenus(id) {
      this.loading = true
      this.totalMenu = 0
      this.tableDataMenu = []
      GetMenuTemplateDetailList({
        MenuTemplateId: id,
      }).then((res) => {
        let list = res.Content
        if(res.Code==-1) {
          this.$notify.info({
            title:'消息',
            message: '查询菜单模板详情失败'
          });
          return;
        }
        var a = JSON.stringify(list).replace(/Children/g, 'children')
        var b = JSON.parse(a)
        this.tableDataMenu = b;

        this.totalMenu = list.length
        this.loading = false
      })
      .catch((err) => {

      });
    },
    handleUpdateMenu() {
      this.$refs['formMenu'].validate((valid) => {
        if (!valid) {
          return
        } else {
          this.updateRoleMenus()
          this.updateFormMenuVisible = false
        }
      })
    },
    //指定角色菜单
    updateRoleMenus() {
      AddRoleMenu({
        // id: 0,
        roleId: this.formMenu.RoleId,
        menuTemplateId: this.MenuTemplateId
      }).then((res) => {
        if(res.Code==-1) {
          this.$notify.info({
            title:'消息',
            message: '指定菜单失败'
          });
          return;
        }
        this.$notify.info({
          title:'消息',
          message: '指定菜单成功'
        });
        this.getRoleRecords()
      })
      .catch((err) => {

      });
    },
    // 所属事业部
    handleGetTenant(){
      GetTenantList({

      })
      .then((res) => {
        let obj = res.Content;
        if(res.Code==-1) {
          this.$notify.info({
            title:'消息',
            message: '所属事业部查询失败'
          });
          return;
        }
        this.AgencyTree = obj
      })
      .catch((err) => {

      });
    },
    //
    TenanAddSelected(id) {
      this.addformRole.AgencyId = id
    },
    TenanEditSelected(id) {
      this.editformRole.AgencyId = id
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/mixin.scss';
// 编辑按钮
.el-icon-edit{
  cursor: pointer;
}
.el-icon-delete{
  cursor: pointer;
}
.el-icon-r10{
  margin-right: 10px;
}
.value-style{
	@include font_color("font2_color_white");
}
@media only screen and (max-aspect-ratio: 1920/920) {

}
@media only screen and (min-aspect-ratio: 1920/1000) {

}
</style>
